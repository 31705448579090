import { DocumentData, QueryDocumentSnapshot } from "@firebase/firestore/dist/lite";
import { SessionPurchase } from "../sessions/session.model";

export const PURCHASE_PATH = 'purchasePath';

export class Purchase {
  constructor(
    public datum: Date,
    public aantal: number,
    public code: string,
    public email: string,
    public group: boolean,
    public sessions: SessionPurchase[],
    public id?: string,
  ) { }
}

export const purchaseConverter = {
  toFirestore: (purchase: Purchase): DocumentData => {
    return {
      datum: purchase.datum,
      aantal: purchase.aantal,
      code: purchase.code,
      email: purchase.email,
      sessions: purchase.sessions,
      group: purchase.group
    }
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot): Purchase => {
    const data = snapshot.data();
    return new Purchase(data.datum.seconds, data.aantal, data.code, data.email, data.group, data.sessions)
  }
}
