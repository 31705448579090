import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { CalculatedTimes, } from '../../dashboard/statistics/times/times.component';
import { Purchase } from '../../services/purchases/purchase.model';
import { Review } from '../../services/reviews/review.model'
import { PurchaseService } from '../../services/purchases/purchase.service';
import { Knooppunt, Session, Object } from '../../services/sessions/session.model';
import { SessionService } from '../../services/sessions/session.service';
import { Timer } from './time.model';
import { ReviewService } from 'src/app/services/reviews/review.service';
import { Performance } from 'src/app/services/performances/performance.model';

export class ReviewScores {
  constructor(
    public name: string | number,
    public total: number,
    public emoji?: string
  ) { }
}

export class ReviewsArray {
  constructor(
    public name: string,
    public scores: ReviewScores[]
  ) { }
}


@Injectable({
  providedIn: 'root'
})
export class CityService {

  sessions: Session[];
  purchases: Purchase[];
  city: string;

  private db = this.firebaseService.db


  spotsNijmegen = [
    'duif', 'ivens', 'anker', 'labyrinth', 'maarten', 'weeshuis-kistje', 'weeshuis', 'apostelen', 'kleurtjes', 'olaf', 'waterwolf', 'zakkenman', 'sintnicolaas', 'gedenksteen', 'zeemeermannen', 'eindopdracht'
  ]

  spotsEindhoven = [
    'fotograaf', 'wallOfFame', 'dokahok', 'zoek-mama-willem', 'vind-arbeider', 'linnenfabriek', 'linnenweverij', 'hoed', 'sjaal', 'boekje', 'slot', 'trommelaar', 'skelet', 'schepmans', 'graafplek', 'spijker', 'zoek-kistje', 'kist', 'maximahof', 'lightbulb', 'kaartBrievenbussen', 'brievenbussen', 'code'
  ]

  cities = [
    {
      name: 'nijmegen',
      knooppunten: [
        'kronenburgerpark', 'waalkade', 'begijnenstraat', 'sintstevenskerk', 'grotestraat', 'valkhof', 'belvedere'
      ],
      spots: [
        'duif', 'ivens', 'anker', 'labyrinth', 'maarten', 'weeshuis-kistje', 'weeshuis', 'apostelen', 'kleurtjes', 'olaf', 'waterwolf', 'zakkenman', 'sintnicolaas', 'gedenksteen', 'zeemeermannen', 'eindopdracht'
      ]
    },
    {
      name: 'eindhoven',
      knooppunten: ['effenaar', 'fabrieksroute', 'waaghals', 'catharinakerk', 'regent', 'philipsmuseum', 'etos'],
      spots: [
        'fotograaf', 'wallOfFame', 'dokahok', 'zoek-mama-willem', 'vind-arbeider', 'linnenfabriek', 'linnenweverij', 'hoed', 'sjaal', 'boekje', 'slot', 'trommelaar', 'skelet', 'schepmans', 'graafplek', 'spijker', 'zoek-kistje', 'kist', 'maximahof', 'lightbulb', 'kaartBrievenbussen', 'brievenbussen', 'code'
      ]
    }
  ]
  knooppuntenNijmegen = [
    'kronenburgerpark', 'waalkade', 'begijnenstraat', 'sintstevenskerk', 'grotestraat', 'valkhof', 'belvedere'
  ]

  knooppuntenEindhoven = ['effenaar', 'fabrieksroute', 'waaghals', 'catharinakerk', 'regent', 'philipsmuseum', 'etos']

  cityNames = [
    {
      name: 'eindhoven',
      knooppunten: this.knooppuntenEindhoven,
      spots: this.spotsEindhoven
    },
    {
      name: 'nijmegen',
      knooppunten: this.knooppuntenNijmegen,
      spots: this.spotsNijmegen
    }
  ]

  pauzeCities = [
    {
      name: 'nijmegen',
      pauzeURL: [{
        time: 1424,
        url:
          "nijmegen/kronenburgerpark/duif/brief"
      }
      ]
    }
  ]



  reviewScores: ReviewsArray[] = [
    {
      name: 'general',
      scores: [
        { name: 1, total: 0 },
        { name: 2, total: 0 },
        { name: 3, total: 0 },
        { name: 4, total: 0 },
        { name: 5, total: 0 },
        { name: 6, total: 0 },
        { name: 7, total: 0 },
        { name: 8, total: 0 },
        { name: 9, total: 0 },
        { name: 10, total: 0 }
      ]
    },
    {
      name: 'puzzle',
      scores: [
        { name: 'te makkelijk', total: 0 },
        { name: 'makkelijk', total: 0 },
        { name: 'gemiddeld', total: 0 },
        { name: 'moeilijk', total: 0 },
        { name: 'te moeilijk', total: 0 }
      ]
    },

    {
      name: 'route',
      scores: [
        { emoji: '😠', name: 'verschrikkelijk', total: 0 },
        { emoji: '🙁', name: 'slecht', total: 0 },
        { emoji: '😊', name: 'oke', total: 0 },
        { emoji: '😁', name: 'leuk', total: 0 },
        { emoji: '😍', name: 'super', total: 0 }
      ]
    },
    {
      name: 'story',
      scores: [
        { emoji: '😠', name: 'verschrikkelijk', total: 0 },
        { emoji: '🙁', name: 'slecht', total: 0 },
        { emoji: '😊', name: 'oke', total: 0 },
        { emoji: '😁', name: 'leuk', total: 0 },
        { emoji: '😍', name: 'super', total: 0 }
      ]
    }

  ]

  reviews: Review[] = []



  performancePerMonthCities = [
    {
      name: 'nijmegen',
      performance: [
        {
          month: 'January',
          sessions: 50,
          purchases: 44,
          reviews: 45,
          revenue: 100
        },
        {
          month: 'February',
          sessions: 90,
          purchases: 88,
          reviews: 22,
          revenue: 100
        },
        {
          month: 'March',
          sessions: 56,
          purchases: 64,
          reviews: 22,
          revenue: 100
        },
        {
          month: 'April',
          sessions: 57,
          purchases: 122,
          reviews: 22,
          revenue: 100
        },
        {
          month: 'May',
          sessions: 50,
          purchases: 24,
          reviews: 22,
          revenue: 100
        },
        {
          month: 'June',
          sessions: 50,
          purchases: 94,
          reviews: 22,
          revenue: 78
        },
        {
          month: 'July',
          sessions: 50,
          purchases: 44,
          reviews: 29,
          revenue: 75
        },
        {
          month: 'August',
          sessions: 50,
          purchases: 44,
          reviews: 172,
          revenue: 578
        },
        {
          month: 'September',
          sessions: 50,
          purchases: 44,
          reviews: 22,
          revenue: 830
        },
        {
          month: 'October',
          sessions: 50,
          purchases: 44,
          reviews: 22,
          revenue: 330
        },
        {
          month: 'November',
          sessions: 50,
          purchases: 44,
          reviews: 22,
          revenue: 50
        },
        {
          month: 'December',
          sessions: 50,
          purchases: 44,
          reviews: 22,
          revenue: 100
        },
      ]
    }
  ]
  constructor(
    private router: Router,
    private sessionService: SessionService,
    private purchaseService: PurchaseService,
    private firebaseService: FirebaseService,
    private reviewService: ReviewService
  ) { }

  async getCitySessions(name: string, from: Date, to: Date) {
    const sessions = await this.sessionService.getSessions(name, from, to);
    this.sessions = sessions;
    return sessions;
  }

  async getCityPurchases(name: string, from: Date, to: Date) {
    const purchases = await this.purchaseService.getPurchases(name, from, to);
    this.purchases = purchases;
    return purchases;
  }


  getCityPerformance(name: string) {
    const sessions = this.sessions.length;
    const purchases = this.purchases.length;
    const reviews = this.reviews.length
    const revenue = 100;
    const performance = new Performance(revenue, purchases, sessions, reviews)
    return performance;
  }

  async getCityKnooppuntenData() {

    const sessions = this.sessions;
    const knoopunten: Array<Knooppunt[]> = [];
    for (var session of sessions) {
      knoopunten.push(await this.sessionService.getKnooppuntData(session.path));
    }
    return knoopunten;
  }

  async getReviews(sessions: Session[]) {

    //reset
    this.reviews = [];
    this.reviewScores = [
      {
        name: 'general',
        scores: [
          { name: 1, total: 0 },
          { name: 2, total: 0 },
          { name: 3, total: 0 },
          { name: 4, total: 0 },
          { name: 5, total: 0 },
          { name: 6, total: 0 },
          { name: 7, total: 0 },
          { name: 8, total: 0 },
          { name: 9, total: 0 },
          { name: 10, total: 0 }
        ]
      },
      {
        name: 'puzzle',
        scores: [
          { name: 'te makkelijk', total: 0 },
          { name: 'makkelijk', total: 0 },
          { name: 'gemiddeld', total: 0 },
          { name: 'moeilijk', total: 0 },
          { name: 'te moeilijk', total: 0 }
        ]
      },

      {
        name: 'route',
        scores: [
          { emoji: '😠', name: 'verschrikkelijk', total: 0 },
          { emoji: '🙁', name: 'slecht', total: 0 },
          { emoji: '😊', name: 'oke', total: 0 },
          { emoji: '😁', name: 'leuk', total: 0 },
          { emoji: '😍', name: 'super', total: 0 }
        ]
      },
      {
        name: 'story',
        scores: [
          { emoji: '😠', name: 'verschrikkelijk', total: 0 },
          { emoji: '🙁', name: 'slecht', total: 0 },
          { emoji: '😊', name: 'oke', total: 0 },
          { emoji: '😁', name: 'leuk', total: 0 },
          { emoji: '😍', name: 'super', total: 0 }
        ]
      }
    ]

    const reviews: Review[] = [];
    const scores = [...this.reviewScores];

    for (var session of sessions) {
      const dateNewReview = new Date('10-14-2022');
      if (dateNewReview > session.created) { }
      else {
        const newReview = await this.reviewService.getReview(session.path)
        if (newReview) {
          newReview.path = session.path;
          reviews.push(newReview);
        }
      }
    }


    for (var review of reviews) {

      const generalScore = scores.find(a => a.name === 'general')
      const storyScore = scores.find(a => a.name === 'story')
      const routeScore = scores.find(a => a.name === 'route')
      const puzzlelScore = scores.find(a => a.name === 'puzzle')

      const reviewScores = [generalScore, storyScore, routeScore, puzzlelScore]

      for (const scores of reviewScores) {

        let index: number
        if (scores.name === 'general') index = scores.scores.findIndex(a => a.name === review.general)
        if (scores.name === 'story') index = scores.scores.findIndex(a => a.emoji === review.story)
        if (scores.name === 'route') index = scores.scores.findIndex(a => a.emoji === review.difficulty)
        if (scores.name === 'puzzle') index = scores.scores.findIndex(a => a.name === review.puzzles[0])

        if (index >= 0) scores.scores[index].total = scores.scores[index].total + 1

      }
    }
    // console.log(scores);
    this.reviews = reviews
    return { scores: scores, reviews: reviews };
  }

  averageTimeKnooppuntenCalculator(knooppunten: Array<Knooppunt[]>) {
    const city = this.cityNames.find(x => x.name === this.city);
    const cityKnooppunten: string[] = [...city.knooppunten];
    let averageKnooppunt: Knooppunt[] = [];
    cityKnooppunten.forEach(x => {
      averageKnooppunt.push(new Knooppunt(null, null, x));
    })

    averageKnooppunt.forEach((knooppunt, index) => {
      let allCurrentKnooppuntTimes = []
      for (var y of knooppunten) {
        const find = y.find(z => z.name === knooppunt.name);
        if (find) allCurrentKnooppuntTimes.push(find.time);
      }
      const averageTime = allCurrentKnooppuntTimes.reduce((a, b) => a + b, 0) / allCurrentKnooppuntTimes.length;
      knooppunt = new Knooppunt(null, averageTime, knooppunt.name)
      averageKnooppunt[index] = knooppunt
    })

    return averageKnooppunt;
  }

  async getCityObjecten() {
    const sessions = this.sessions;
    const objecten: Array<Object[]> = [];
    for (var session of sessions) {
      const objectData = await this.sessionService.getObjectData(session.path);
      if (objectData) objecten.push(objectData);
    }
    return objecten;
  }

  averageTimeObjectenCalculator(objecten: Array<Object[]>) {
    const city = this.cityNames.find(x => x.name === this.city);
    const cityObjecten: string[] = [...city.spots];
    let averageObject: Object[] = [];
    cityObjecten.forEach(x => {
      averageObject.push(new Object(null, null, x));
    })

    averageObject.forEach((object, index) => {
      let allCurrentObjectTimes = []
      let allCurrentObjectSkippedTimes = []
      for (var y of objecten) {
        const find = y.find(z => z.name === object.name);
        if (!find) {
          allCurrentObjectTimes.push(0);
          allCurrentObjectSkippedTimes.push(0)
        }
        else {
          allCurrentObjectTimes.push(find.time);
          allCurrentObjectSkippedTimes.push(find.skipped)
        }
      }
      const averageTime = allCurrentObjectTimes.reduce((a, b) => a + b, 0) / allCurrentObjectTimes.length;
      const averageSkippedTime = allCurrentObjectSkippedTimes.reduce((a, b) => a + b, 0) / allCurrentObjectSkippedTimes.length;
      object = new Object(averageTime, averageSkippedTime, object.name)
      averageObject[index] = object
    })
    return averageObject;
  }

  getCityPauzedTimes(name: string) {
    const city = this.pauzeCities.find(x => x.name === name);
    return city;
  }

  async getCityTimes(sessions: Session[]) {
    const times: Timer[] = [];
    for (var session of sessions) {
      const time = await this.sessionService.getTimes(session.path)
      if (time) times.push(time);
    }
    let totalTimes = {
      total: [],
      paused: [],
      skipped: [],
    }

    for (var time of times) {
      if (!time || !time.end || !time.start) { }
      else {
        totalTimes.total.push(time.end - time.start)
        totalTimes.paused.push(time.pauze)
        totalTimes.skipped.push(time.skipped)
      }
    }

    const inputTimes: CalculatedTimes = {
      total: totalTimes.total.reduce((a, b) => a + b, 0) / totalTimes.total.length,
      paused: totalTimes.paused.reduce((a, b) => a + b, 0) / totalTimes.paused.length,
      skipped: totalTimes.skipped.reduce((a, b) => a + b, 0) / totalTimes.skipped.length,
    }

    return inputTimes;
  }

  getCityMonthPerformance(name: string) {
    const city = this.performancePerMonthCities.find(x => x.name === name);
    if (city) return city.performance;
    else return null
  }

  getCitySpots(name: string) {
    const city = this.cities.find(city => city.name === name);
    return city.spots;
  }

  getCityKnooppunten(name: string) {
    const city = this.cities.find(city => city.name === name);
    return city.knooppunten;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url = route.paramMap.get('city');
    // console.log("city: ", url);
    if (url !== 'eindhoven' && url !== 'nijmegen') {
      console.log('%cname provided is not a city, either add the city as type or enjoy your way to the hell gates 🔥', "color:blue");
      this.router.navigateByUrl('/home');
      return false;
    }
    return true;
  }

}
