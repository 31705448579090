import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { CityService } from './cities/city/city.service';

const routes: Routes = [
  {
    path: 'dashboard',
    canLoad: [AuthGuard],
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule)
  },
  {
    path: 'cities/:city',
    canLoad: [AuthGuard],
    loadChildren: () => import('./cities/city/city.module').then(m => m.CityComponentModule),
    canActivate: [CityService]
  },
  {
    path: 'cities/:city/alle-gespeeld',
    canLoad: [AuthGuard],
    loadChildren: () => import('./cities/city/alle-gespeeld/alle-gespeeld.module').then(m => m.AlleGespeeldComponentModule),
    canActivate: [CityService]
  },
  {
    path: 'cities/:city/:session',
    canLoad: [AuthGuard],
    loadChildren: () => import('./session/session.module').then(m => m.SessionComponentModule),
    canActivate: [CityService]
  },
  {
    path: 'purchase',
    canLoad: [AuthGuard],
    loadChildren: () => import('./purchase/purchase.module').then(m => m.PurchaseModule)
  },
  {
    path: 'purchases',
    canLoad: [AuthGuard],
    loadChildren: () => import('./purchases/purchases.module').then(m => m.PurchasesModule)
  },
  {
    path: 'find-purchases',
    canLoad: [AuthGuard],
    loadChildren: () => import('./find-purchase/find-purchase.module').then(m => m.FindPurchaseModule)
  },
  {
    path: 'cities',
    canLoad: [AuthGuard],
    loadChildren: () => import('./cities/cities.module').then(m => m.CitiesPageModule)
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'change-mail-data',
    canLoad: [AuthGuard],
    loadChildren: () => import('./change-mail-data/change-mail-data.module').then(m => m.ChangeMailComponentModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
