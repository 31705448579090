import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  index = 0;
  title = ['dashboard', 'aankoop', 'aankopen', 'zoek aankoop', 'steden', 'profiel', 'instellingen']
  // menu = ['', 'purchase', 'live', 'cities', 'help', 'profile', 'settings']

  hamburgerClass = '';

  ngOnInit() {
    const url = this.router.url;
    const splitUrl = url.split("/");
    console.log(splitUrl)
    if (!splitUrl[1]) return;
    switch (splitUrl[1]) {
      case 'purchase': this.index = 1;
      break;
      case 'purchases': this.index = 2
      break
      case 'find-purchases': this.index = 3
      break;
      case 'cities': this.index = 4;
      break;
      default: this.index = 0;
    }
  }


  toggleClass() {
    if (this.hamburgerClass === 'open') this.hamburgerClass = ''
    else this.hamburgerClass = 'open'
  }

  activeChange(index: number) {
    this.index = index;
  }
  toDashboard() {
    this.router.navigateByUrl('/dashboard');
    this.toggleClass()
    this.activeChange(0)
  }

  toPurchases() {
    this.router.navigateByUrl('/purchases')
    this.toggleClass()
    this.activeChange(2)
  }

  toFindPurchase() {
    this.router.navigateByUrl('/find-purchases');
    this.toggleClass()
    this.activeChange(3)
  }

  toAddGroup() {
    this.router.navigateByUrl('/purchase');
    this.toggleClass()
    this.activeChange(1)
  }
  toCities() {
    this.router.navigateByUrl('/cities');
    this.toggleClass()
    this.activeChange(4)
  }

  toHelpDesk() {

  }

  toProfile() {

  }
  toSettings() {

  }

  toChangeMailData(){
    this.router.navigateByUrl('/change-mail-data');
    this.toggleClass()
    this.activeChange(7)
  }
}
