/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from './user.model';
import { StorageService } from '../services/storage/storage.service';

export interface AuthResponseData {
  kind: string;
  idToken: string;
  email: string;
  displayName: string;
  photoURL: string,
  refreshToken: string;
  localId: string;
  expiresIn: string;
  registered?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  // tslint:disable-next-line:variable-name
  private _user = new BehaviorSubject<User>(null);
  private activeLogoutTimer: any;

  get userIsAuthenticated() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return !!user.token;
        } else {
          return false;
        }
      }));
  }

  get userId() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.id;
        } else {
          return null;
        }
      }));
  }
  get token() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.token;
        } else {
          return null;
        }
      }));
  }

  get user() {
    return this._user.getValue()
  }
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
    console.log('production: ', environment.production)
  }

  async autoLogin() {
    return await this.storageService.getItem('authData').then(
      (storedData) => {
        if (!storedData) {
          return null;
        }
        const parsedData = JSON.parse(storedData) as {
          token: string;
          tokenExpirationDate: string;
          userId: string;
          email: string;
          displayName: string,
          photoURL: string,
        };
        const expirationTime = new Date(parsedData.tokenExpirationDate);
        if (expirationTime <= new Date()) {
          return null;
        }
        const user = new User(
          parsedData.userId,
          parsedData.email,
          parsedData.displayName,
          parsedData.photoURL,
          parsedData.token,
          expirationTime
        );
        return user;
      }).then((user: User) => {
        if (user) {
          this._user.next(user);
          this.autoLogout(user.tokenDuration);
        }
        return user;
      }).then((user: User) => !!user
      );
  }


  signup(email: string, password: string) {
    return this.http.post<AuthResponseData>(`https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${environment.firebaseAPIKey
      }`, { email, password, returnSecureToken: true }
    ).pipe(tap(this.setUserData.bind(this)));
  }

  login(email: string, password: string) {
    return this.http.post<AuthResponseData>(`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${environment.firebaseAPIKey}`,
      { email, password, returnSecureToken: true }
    ).pipe(tap(this.setUserData.bind(this)));
  }

  async logout() {
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
    this._user.next(null);
    await this.storageService.removeKey('AuthData');
  }

  private autoLogout(duration: number) {
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
    this.activeLogoutTimer = setTimeout(() => {
      this.logout();
    }, duration);
  }

  private setUserData(userData: AuthResponseData) {
    console.log('data: ', userData)
    const expirationTime = new Date(new Date().getTime() + (+userData.expiresIn * 1000));
    const user = new User(
      userData.localId,
      userData.email,
      userData.displayName,
      userData.photoURL,
      userData.idToken,
      expirationTime
    );
    this._user.next(user);
    this.autoLogout(user.tokenDuration);
    this.storeAuthData(userData.localId, userData.idToken, expirationTime.toISOString(), userData.email, userData.displayName);
  }
  updateUserData(displayName: string, email: string, photoURL: string) {
    const user = new User(
      this.user.id,
      email,
      displayName,
      photoURL,
      this.user.token,
      this.user.tokenExpirationDate,
    )
    this._user.next(user)
  }
  private async storeAuthData(
    userId: string,
    token: string,
    tokenExpirationDate: string,
    email: string,
    displayName: string
  ) {
    const data = JSON.stringify({
      userId,
      token,
      tokenExpirationDate,
      email,
      displayName,
    });
    await this.storageService.setItem('authData', data);
  }

  ngOnDestroy() {
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
  }
}
