import { Injectable } from '@angular/core';
import { getAuth, onAuthStateChanged, updateProfile } from "firebase/auth";
import { AlertController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { AuthService } from 'src/app/auth/auth.service';



@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private alertCtrl: AlertController,
    private firebaseService: FirebaseService,
    private authService: AuthService,
  ) { }

  getOnlineUser() {
    // return this.authService.user;
    onAuthStateChanged(this.firebaseService.auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        console.log("received user:", user)
        // ...
      } else {
        console.log("no user to be found")
        // User is signed out
        // ...
      }
    });

  }

  getUser() {
    return this.authService.user
  }

  async updateUser(name: string) {
    try {
      await updateProfile(this.firebaseService.auth.currentUser, {
        displayName: name
      })
      this.authService.updateUserData(name, this.authService.user.email, this.authService.user.photoURL)
    } catch (error) {
      console.log("error:", error)
    }
  }



}
