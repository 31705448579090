import { CollectionReference, DocumentData, DocumentReference, QueryDocumentSnapshot } from "firebase/firestore/lite";
import { Timer, TimeURL } from "src/app/cities/city/time.model";
import { Review } from "../reviews/review.model";


export type CityName = 'eindhoven' | 'nijmegen';
export type SnapStatus = 'checked' | 'internet' | 'code' | 'database';

export class Session {
  constructor(
    public created: Date,
    public endTime: string,
    public group: boolean,
    public pausedTimes: TimeURL[],
    public purchasePath: string,
    public times: Timer,
    public path?: string,
    public createdString?: string,
    public email?: string,
    public review?: Review,
  ) { }
}

export class SessionPurchase {
  constructor(
    public ref: DocumentReference,
    public finished: boolean
  ) { }
}


export class Knooppunt {
  constructor(
    public finished: string,
    public time: number,
    public name?: string,
  ) { }

}

export class Object {
  constructor(
    public time: number,
    public skipped: number,
    public name?: string,
  ) { }
  toString() {
    return this.time + ', ' + this.skipped
  }
}


export const knoopppuntConverter = {
  toFirestore: (knooppunt: Knooppunt): DocumentData => {
    return {
      time: knooppunt.time,
      finished: knooppunt.finished
    }
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot): Knooppunt => {
    const data = snapshot.data();
    return new Knooppunt(data.finished, data.time)
  }
}

export const objectConverter = {
  toFirestore: (object: Object): DocumentData => {
    return {
      time: object.time,
      skipped: object.skipped
    }
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot): Object => {
    const data = snapshot.data();
    return new Object(data.time, data.skipped)
  }
}


export const sessionConverter = {
  toFirestore: (session: Session): DocumentData => {
    return {
      created: session.created,
      endTime: session.endTime,
      group: session.group,
      pausedTimes: session.pausedTimes,
      purchasePath: session.purchasePath,
      times: session.times,
    }
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot): Session => {
    const data = snapshot.data();
    return new Session(new Date(data.created.seconds * 1000), data.endTime, data.group, data.pausedTimes, data.purchasePath, data.times)
  }
}
