import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { addDoc, collection, where, orderBy, query, getDocs, limit, doc, updateDoc } from 'firebase/firestore/lite';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';

import { environment } from 'src/environments/environment';
import { Purchase, purchaseConverter } from './purchase.model';


export const DEVELOPMENT_CITY_TEST_COLLECTION = 'test-cities'
export const CITY_COLLECTION = 'cities'
const PURCHASES_COLLECTION = 'purchases';

@Injectable({
  providedIn: 'root'
})

export class PurchaseService {

  development = false;
  private db = this.firebaseService.db


  sending = false;

  constructor(
    private firebaseService: FirebaseService,
    private http: HttpClient
  ) {}

  randomCode(length: number = 5) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  async sendMail(postData: { tour: string, email: string, code: string, name: string, aantal: number, prijs: number, type: string }) {
    const url = environment.purchaseWebhook;
    console.log(url);

    try {
    return new Promise<boolean>(resolve => {
      this.http
        .get(url, { params: postData })
        .subscribe((response) => {
          console.log(response);
          if (response) resolve(true)
          else resolve(false)
        })

    })
  }catch(error){
    return error
    console.log('error adding', error)
  }
  }
  async addPurchase(city: string, aantal: number, email: string, group: boolean, code: string) {

    let cityCollection;
    if (this.development) cityCollection = DEVELOPMENT_CITY_TEST_COLLECTION;
    else cityCollection = CITY_COLLECTION
    await addDoc(collection(this.db, cityCollection, city, PURCHASES_COLLECTION), {
      aantal: aantal,
      datum: new Date(),
      email: email,
      group: group,
      code: code
    })
  }

  async findCodePurchase(city: string, code: string): Promise<Purchase[]> {
    const q = query(collection(this.db, CITY_COLLECTION, city, PURCHASES_COLLECTION), where('code', '==', code));
    const snapshot = await getDocs(q);
    if(snapshot.empty) return []
    const purchases = snapshot.docs.map(doc => {
      const data = doc.data() as Purchase;
      const id = doc.id
      return new Purchase(data.datum, data.aantal, data.code, data.email, data.group, data.sessions, id)
    });
    console.log(purchases);
    return purchases
  }


  async updateDatumToTimestamp(city: string, purchaseId: string, datumString: string): Promise<void> {
    const docRef = doc(this.db, CITY_COLLECTION, city, PURCHASES_COLLECTION, purchaseId);
    const timestamp = new Date(datumString) // Convert to timestamp
    console.log(timestamp)
    await updateDoc(docRef, { datum: timestamp });
  }

  async getLastAddedPurchases(city: string, limitCount: number): Promise<Purchase[]> {
    const q = query(collection(this.db, CITY_COLLECTION, city, PURCHASES_COLLECTION), orderBy('datum', 'desc'), limit(limitCount));
    const snapshot = await getDocs(q);
    if(snapshot.empty) return []
    const purchases = snapshot.docs.map(doc => {
      const data = doc.data() as Purchase;
      const id = doc.id
      const timestamp = (data.datum as any).seconds * 1000
      const date = new Date(timestamp)
      return new Purchase(date, data.aantal, data.code, data.email, data.group, data.sessions, id)
    });
    console.log(purchases);
    return purchases
  }

  async getPurchases(city: string, from: Date, to: Date) {
    const q = query(collection(this.db, CITY_COLLECTION, city, PURCHASES_COLLECTION),
      where("datum", ">=", from),
      where("datum", "<=", to),
      orderBy('datum'))
      .withConverter(purchaseConverter)
    try {
      const querySnapshot = await getDocs(q);
      let purchases: Purchase[] = []
      if (querySnapshot.empty) {
        // this.firebaseService.errorAnswer('code')
        console.log("empty");
        return purchases;
      }

      querySnapshot.forEach((doc) => {
        const purchase = doc.data()
        purchases.push(new Purchase(purchase.datum, purchase.aantal, purchase.code, purchase.email, purchase.group, purchase.sessions))
      })

      return purchases;
    } catch (error) {
      console.log(error);
      console.log('code: ', error.code);
      const errorCase = this.firebaseService.firebaseError(error)
      this.firebaseService.errorAnswer(errorCase);
    }
  }
}
