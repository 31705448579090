export class User {
  constructor(
    public id: string,
    public email: string,
    public displayName: string,
    public photoURL: string,
    private _token: string,
    private _tokenExpirationDate: Date,
  ) { }

  get token() {
    if (!this._tokenExpirationDate || this._tokenExpirationDate <= new Date()) return null;
    return this._token;
  }

  get tokenDuration() {
    if (!this.token) return 0;
    return this._tokenExpirationDate.getTime() - new Date().getTime();
  }

  get tokenExpirationDate() {
    if (!this._tokenExpirationDate || this._tokenExpirationDate <= new Date()) return null;
    return this._tokenExpirationDate
  }
}
