import { DocumentData, QueryDocumentSnapshot } from "@firebase/firestore/dist/lite";

export class Performance {
  constructor(
    public revenue: number,
    public purchases: number,
    public sessions: number,
    public reviews: number,

  ) { }
}


export const performanceConverter = {
  toFirestore: (performance: Performance): DocumentData => {
    return {
      revenue: performance.revenue,
      purchases: performance.purchases,
      sessions: performance.reviews,
      reviews: performance.reviews
    }
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot): Performance => {
    const data = snapshot.data();
    return new Performance(data.revenue, data.purchases, data.sessions, data.reviews)
  }
}
