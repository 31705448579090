// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseAPIKey: 'AIzaSyBaXeGkj_-SHojIcTfzYqPB2NtUXKHn17s',
  firebaseHostingAPIKey: 'AIzaSyBWGDpA-jiToDd4nPKbIy4MEzuNPdvj8aM',
  purchaseWebhook: 'https://n8n.thecityescape.nl/webhook/99b1baff-5a3b-4ec3-8d1a-c418de0ebca5'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
