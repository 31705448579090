import { Injectable } from '@angular/core';
import { collection, addDoc, getDocs, query, where, doc, updateDoc, setDoc, arrayUnion, getDoc, arrayRemove, orderBy } from 'firebase/firestore/lite';
import { KnooppuntTimeSetup, ObjectTimeSetup, Timer, TimeURL } from 'src/app/cities/city/time.model';

import { FirebaseService } from '../firebase/firebase.service';
import { StorageService } from '../storage/storage.service';
import { Review, reviewConverter } from '../../services/reviews/review.model'
import { knoopppuntConverter, objectConverter, Knooppunt, Object, SessionPurchase, CityName, Session, sessionConverter } from './session.model';

const DEVELOPMENT_CITY_TEST_KEY = 'test-'
const SESSIONS_COLLECTION = 'sessions'
const KNOOPPUNT_COLLECTION = 'knooppunten';
const OBJECTEN_COLLECTION = 'objecten'
const SESSION_PATH = 'sessionPath';


@Injectable({
  providedIn: 'root'
})

export class SessionService {

  private db = this.firebaseService.db

  private _cityCollection = 'cities'
  get cityCollection() {
    return this._cityCollection;
  }
  constructor(
    private firebaseService: FirebaseService,
    private storageService: StorageService
  ) { }

  async getSessions(city: string, from: Date, to: Date) {
    const q = query(collection(this.db, this.cityCollection, city, SESSIONS_COLLECTION),
      where("created", ">=", from),
      where("created", "<=", to),
      orderBy('created', 'desc'))
      .withConverter(sessionConverter)
    try {
      const querySnapshot = await getDocs(q);
      let sessions: Session[] = [];
      if (querySnapshot.empty) {
        console.log('empty')
        return sessions = [];
      }

      querySnapshot.forEach((doc) => {
        // console.log(doc.ref, ' => ', doc.data())
        const session = doc.data()
        sessions.push(new Session(session.created, session.endTime, session.group, session.pausedTimes, session.purchasePath, session.times, doc.ref.path))
      })

      return sessions;
    } catch (error) {
      console.log(error);
      console.log('code: ', error.code);
      const errorCase = this.firebaseService.firebaseError(error)
      this.firebaseService.errorAnswer(errorCase);
    }

  }

  // checkSessions(sessions: SessionPurchase[]) {
  //   return sessions.every(session => session.finished === true);
  // }

  async getSession(sessionPath: string) {
    if (!sessionPath) return null;
    const sessionRef = doc(this.db, sessionPath).withConverter(sessionConverter);
    const sessionSnap = await getDoc(sessionRef);

    try {
      if (sessionSnap.exists()) {
        return sessionSnap.data();
      } else
        console.log("No such document")
      return null
    } catch (error) {
      console.log('error: ', error)
      this.firebaseService.firebaseError(error);
    }
  }

  async getKnooppuntData(sessionPath: string) {
    if (!sessionPath) return null;
    const q = query(collection(this.db, sessionPath, KNOOPPUNT_COLLECTION)).withConverter(knoopppuntConverter);
    try {
      const querySnapshot = await getDocs(q);
      let knooppunten: Knooppunt[] = [];
      if (querySnapshot.empty) {
        console.log('empty')
        return knooppunten
      }

      querySnapshot.forEach((doc) => {
        const knooppunt = doc.data()
        knooppunten.push(new Knooppunt(knooppunt.finished, knooppunt.time, doc.ref.id))
      })

      return knooppunten;
    } catch (error) {
      console.log('code: ', error.code);
      const errorCase = this.firebaseService.firebaseError(error)
      this.firebaseService.errorAnswer(errorCase);
    }

  }

  async getObjectData(sessionPath: string) {
    if (!sessionPath) return null;
    const q = query(collection(this.db, sessionPath, OBJECTEN_COLLECTION)).withConverter(objectConverter);
    try {
      const querySnapshot = await getDocs(q);
      let objecten: Object[] = [];
      if (querySnapshot.empty) {
        console.log('empty')
        return objecten
      }

      querySnapshot.forEach((doc) => {
        const object = doc.data()
        const newObject = new Object(object.time, object.skipped, doc.ref.id);
        objecten.push(newObject)
      })

      return objecten;
    } catch (error) {
      console.log('code: ', error.code);
      const errorCase = this.firebaseService.firebaseError(error)
      this.firebaseService.errorAnswer(errorCase);
    }

  }

  async getTimes(sessionPath: string) {
    if (!sessionPath) return null;
    const sessionRef = doc(this.db, sessionPath).withConverter(sessionConverter);
    const sessionSnap = await getDoc(sessionRef);

    try {
      if (sessionSnap.exists()) {
        return sessionSnap.data().times;
      } else
        console.log("No such document")
      return null
    } catch (error) {
      console.log('error: ', error)
      this.firebaseService.firebaseError(error);
    }
  }

  async addSessionData(sessionPath: any, times: Timer, pausedTimes: TimeURL[], endTime: string) {
    if (!sessionPath) return null;
    const docRef = doc(this.db, sessionPath);
    await updateDoc(docRef, {
      times: times,
      pausedTimes: pausedTimes,
      endTime: endTime
    });
  }

  reorderKnooppunten(receivedKnooppunten: Knooppunt[], order: string[]) {

    let newKnooppunten: Knooppunt[] = [];
    order.forEach(x => {
      newKnooppunten.push(new Knooppunt(null, null, x));
    })

    newKnooppunten.forEach((knooppunt, index) => {
      const find = receivedKnooppunten.find(recKnooppunt => recKnooppunt.name === knooppunt.name)
      if (find) {
        knooppunt = new Knooppunt(null, find.time, knooppunt.name)
        newKnooppunten[index] = knooppunt
      }
    })

    return newKnooppunten;
  }

  reorderSpots(receivedSpots: Object[], order: string[]) {

    let newObjects: Object[] = [];
    order.forEach(x => {
      newObjects.push(new Object(null, null, x));
    })

    newObjects.forEach((object, index) => {
      const find = receivedSpots.find(z => z.name === object.name);
      if (find) {
        const newObject = new Object(find.time, find.skipped, object.name)
        newObjects[index] = newObject
      }
    })

    return newObjects;
  }

  async getEmail(city: string, purchasePath: string) {
    // let test = collection(this.db, 'cities', 'eindhoven', 'purchases')
    // const querySnapshot = await getDocs(test);
    // console.log(querySnapshot)
    // return((await getDoc(doc(this.db, 'cities', city, 'purchases', purchasePath))).data().email)

    const purchaseRef = doc(this.db, purchasePath)
    const purchaseSnap = await getDoc(purchaseRef);

    try {
      if (purchaseSnap.exists()) {
        return purchaseSnap.data().email
      } else
        console.log("No such document")
      return null
    } catch (error) {
      console.log('error: ', error)
      this.firebaseService.firebaseError(error);
      return null
    }
  }
}


