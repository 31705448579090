import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { addDoc, collection, where, orderBy, query, getDocs } from 'firebase/firestore/lite';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';

import { environment } from 'src/environments/environment';
import { CityName } from '../sessions/session.model';
import { Review, reviewConverter } from './review.model';

export const DEVELOPMENT_CITY_TEST_COLLECTION = 'test-cities'
export const CITY_COLLECTION = 'cities'
const REVIEWS_COLLECTION = 'reviews';
const REVIEW_COLLECTION = 'review';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {


  development = false;
  private db = this.firebaseService.db
  sending = false;

  constructor(
    private firebaseService: FirebaseService,
    private http: HttpClient
  ) {
  }

  async getReview(sessionPath: string) {
    if (!sessionPath) return null;
    const q = query(collection(this.db, sessionPath, REVIEW_COLLECTION), where("created", ">=", new Date(10 - 14 - 2022))).withConverter(reviewConverter);
    try {
      const querySnapshot = await getDocs(q);
      let review: Review;
      if (querySnapshot.empty) {
        // console.log('empty')
        return null;
      }

      querySnapshot.forEach((doc) => {
        const data = doc.data()
        const newReview = new Review(data.difficulty, data.feedback, data.general, data.puzzles, data.story, data.created)
        review = newReview;
      })

      return review;
    } catch (error) {
      console.log('code: ', error.code);
      const errorCase = this.firebaseService.firebaseError(error)
      this.firebaseService.errorAnswer(errorCase);
    }

  }

  async getReviews(city: string, from: Date, to: Date) {
    const q = query(collection(this.db, CITY_COLLECTION, city, REVIEWS_COLLECTION),
      where("created", ">=", from),
      where("created", "<=", to),
      orderBy('created'))
      .withConverter(reviewConverter)
    try {
      const querySnapshot = await getDocs(q);
      let reviews: Review[] = []
      if (querySnapshot.empty) {
        // this.firebaseService.errorAnswer('code')
        // console.log("empty");
        return reviews;
      }

      querySnapshot.forEach((doc) => {
        const data = doc.data()
        reviews.push
          (new Review(data.difficulty, data.feedback, data.general, data.puzzles, data.story, data.created, data.path))
      })

      return reviews;
    } catch (error) {
      console.log(error);
      console.log('code: ', error.code);
      const errorCase = this.firebaseService.firebaseError(error)
      this.firebaseService.errorAnswer(errorCase);
    }
  }
}
