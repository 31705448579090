import { Component, OnInit } from '@angular/core';
import { User } from '../auth/user.model';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

  user: User

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
    this.user = this.userService.getUser();
    console.log('getting user', this.user)
  }

}
