import { Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore/lite';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { AlertController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

const DATABASE_INDEX_ERROR = 'failed-precondition'

export interface Resp {
  name: string
}

export type ErrorType = 'internet' | 'fout' | 'code' | 'database';
@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  firebaseConfig = {
    apiKey: environment.firebaseAPIKey,
    authDomain: "the-city-escape-721ef.firebaseapp.com",
    databaseURL: "https://the-city-escape-721ef-default-rtdb.firebaseio.com",
    projectId: "the-city-escape-721ef",
    storageBucket: "the-city-escape-721ef.appspot.com",
    messagingSenderId: "743118172914",
    appId: "1:743118172914:web:e6b6351d7021a27a8c566b"
  };

  // Initialize Firebase
  app = initializeApp(this.firebaseConfig);
  db = getFirestore(this.app);


  // Initialize Firebase Authentication and get a reference to the service
  auth = getAuth(this.app);

  constructor(
    private alertCtrl: AlertController,
  ) { }




  errorAnswer(errorType?: ErrorType) {
    let message = '';

    switch (errorType) {
      case 'internet': message = 'Check of je internet aan staat en probeer het dan opnieuw!📡';
        break;
      case 'database': message = 'Er is een probleem met de database, neem contact op met info@thecityescape.nl';
        break;
      case 'code': message = 'De code kan niet gevonden worden. Probeer het opnieuw en neem anders contact met ons op';
        break;
    }

    this.alertCtrl
      .create({
        backdropDismiss: false,
        header: `Probleem met ${errorType}`,
        message: `${message}`,
        buttons: [
          {
            text: 'Oké',
            handler: () => {
            }
          }
        ]
      })
      .then(alertEl => alertEl.present());
  }


  firebaseError(error) {
    let errorType: ErrorType
    switch (error.code) {
      case DATABASE_INDEX_ERROR: {
        console.log('%c Collection moet geindexed worden, in de firebase error staat een linkje', 'color: orange');
        errorType = 'database'
        return errorType;
      }
      default: {
        console.log(error.message)
        return 'database'
      }
    }
  }

}
