import { DocumentData, QueryDocumentSnapshot } from "@firebase/firestore/dist/lite";

export class Review {
  constructor(
    public difficulty: string,
    public feedback: string,
    public general: number,
    public puzzles: Array<string>,
    public story: string,
    public created: Date,
    public path?: string,
  ) { }
}

export const reviewConverter = {
  toFirestore: (review: Review): DocumentData => {
    return {
      difficulty: review.difficulty,
      feedback: review.feedback,
      general: review.general,
      puzzles: review.puzzles,
      story: review.story,
      created: review.created,
    }
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot): Review => {
    const data = snapshot.data();
    const created = new Date(data.created.seconds * 1000)
    return new Review(data.difficulty, data.feedback, data.general, data.puzzles, data.story, created, data.path)
  }
}
